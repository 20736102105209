.react-multi-carousel-list {
  /* justify-content:center;+ */
  padding: 2.5rem 0;
  gap: 10px;
}

.react-multi-carousel-dot--active button {
  background-color: #3da7ff !important;
  border-color: #3da7ff !important;
  transform: scale(1.3);
}

.react-multi-carousel-dot button {
  border-color: #cccccc;
  background-color: #cccccc;
}
