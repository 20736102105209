@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Manrope:wght@400;500;600;700&family=Montserrat:wght@400;500;600&family=Poppins:wght@400;500;600;700&family=Raleway:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sectionContainer {
    @apply flex flex-col px-8 lg:px-16 xl:px-32 items-center;
  }
  .heading {
    @apply font-poppins font-semibold text-2xl lg:text-4xl xl:text-6xl text-center text-blue4 mb-10;
  }

  .contactInput {
    @apply bg-slate-400/10 font-poppins outline-none text-gray-900 placeholder-gray-700 rounded-md border-b px-6 py-4 border-gray-300 transition-all focus:border-b-blue12/40 focus:text-blue4/70 hover:border-blue12/40;
  }
}
